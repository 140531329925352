var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "show-img",
      style: { cursor: _vm.isChangeItemWidth ? "e-resize" : "" },
    },
    [
      _c(
        "a-dropdown",
        { attrs: { trigger: ["contextmenu"] } },
        [
          _c(
            "div",
            { staticClass: "show-img-content" },
            [
              _vm._l(_vm.showOptions, function (showOption, showOptionIndex) {
                return _c(
                  "div",
                  {
                    key: showOptionIndex,
                    staticClass: "show-img-content-item",
                    style: {
                      backgroundColor: _vm.backgroundColor,
                      width:
                        "calc(" +
                        ((showOption.changePer ||
                          100 * (1 / _vm.showOptions.length)) +
                          "%") +
                        " -" +
                        " " +
                        (62 / _vm.showOptions.length +
                          (showOption.changeWidth || 0)) +
                        "px)",
                    },
                    attrs: { id: "show-img-content-item-" + showOptionIndex },
                  },
                  [
                    _c(
                      "div",
                      {
                        ref: "show-img-top-" + showOptionIndex,
                        refInFor: true,
                        staticClass: "show-img-top",
                        on: {
                          mousewheel: (e) =>
                            _vm.handleMouseWheel(e, showOptionIndex),
                          drop: function ($event) {
                            $event.stopPropagation()
                            return ((e) =>
                              _vm.handleDropTop(
                                e,
                                showOptionIndex,
                                showOption.list
                              )).apply(null, arguments)
                          },
                          dragenter: function ($event) {
                            $event.preventDefault()
                          },
                          dragover: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "show-img-top-scroll" },
                          [
                            _vm._l(showOption.list, function (option) {
                              return _c(
                                "span",
                                {
                                  key: option.value,
                                  class: option.checked
                                    ? "show-option show-option-select"
                                    : "show-option",
                                  attrs: { draggable: true, id: option.value },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeOption(option.value)
                                    },
                                    dragend: (e) =>
                                      _vm.dragend(e, option.value),
                                  },
                                },
                                [_vm._v(_vm._s(option.title))]
                              )
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "show-img-top-width",
                                style: {
                                  color:
                                    _vm.backgroundColor == "black"
                                      ? "#fff"
                                      : "",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    showOption.list.find(
                                      (option) => option.checked
                                    ) &&
                                      showOption.list.find(
                                        (option) => option.checked
                                      ).manuscriptList[0] &&
                                      showOption.list.find(
                                        (option) => option.checked
                                      ).manuscriptList[0].imgWidth
                                  ) + "PX"
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                        showOptionIndex == _vm.showOptions.length - 1
                          ? _c("div", { staticClass: "show-img-top-right" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "change-show-img-width",
                                  on: { mousedown: _vm.handleRightMouseDown },
                                },
                                [
                                  _c("a-icon", {
                                    attrs: { type: "double-right" },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.isAddAnnotation &&
                          showOption.list.find((option) => option.checked)
                            .isLatest
                            ? "show-img-bottom show-img-bottom-local"
                            : _vm.isAddAnnotation &&
                              !showOption.list.find((option) => option.checked)
                                .isLatest
                            ? "show-img-bottom show-img-bottom-disabled"
                            : "show-img-bottom",
                        attrs: { id: "show-img-bottom-" + showOptionIndex },
                        on: {
                          scroll: (e) =>
                            _vm.showImgScroll(e, showOption, showOptionIndex),
                          mousedown: function ($event) {
                            $event.stopPropagation()
                            return ((e) =>
                              _vm.showImgMousedown(e, showOption)).apply(
                              null,
                              arguments
                            )
                          },
                          mouseenter: function ($event) {
                            return _vm.scrollEnter(showOptionIndex)
                          },
                          mouseleave: function ($event) {
                            return _vm.scrollleave(showOptionIndex)
                          },
                        },
                      },
                      [
                        showOption.list.find((option) => option.checked) &&
                        showOption.list.find((option) => option.checked)
                          .manuscriptList.length
                          ? _c(
                              "div",
                              {
                                style: _vm.getMaxShowContentStyle(
                                  showOption.list.find(
                                    (option) => option.checked
                                  ),
                                  showOptionIndex
                                ),
                                on: {
                                  contextmenu: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _vm._l(
                                  showOption.list.find(
                                    (option) => option.checked
                                  ).manuscriptList,
                                  function (item) {
                                    return _c(
                                      "div",
                                      {
                                        key:
                                          item.id +
                                          "-" +
                                          showOption.list.find(
                                            (option) => option.checked
                                          ).title,
                                        staticStyle: {
                                          position: "relative",
                                          "line-height": "0",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          ref: item.id + "showLeftImg",
                                          refInFor: true,
                                          class: !showOptionIndex
                                            ? "left-show-img show-page-img"
                                            : "show-page-img",
                                          style: {
                                            width:
                                              _vm.showImgListWidth.length >= 4
                                                ? _vm.showImgListWidth
                                                : "100%",
                                            height: "auto",
                                            filter: !_vm.isDragOption
                                              ? ""
                                              : "blur(3px)",
                                          },
                                          attrs: {
                                            id: item.id + "showLeftImg",
                                            src: item.filePath,
                                          },
                                          on: {
                                            dragstart: function ($event) {
                                              $event.preventDefault()
                                            },
                                          },
                                        }),
                                        _vm.selectedPlatte
                                          ? _c("div", {
                                              staticClass: "img-border",
                                            })
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                !_vm.isDownLoad && !_vm.isChangeItemWidth
                                  ? _c("div", {
                                      style: {
                                        backgroundColor: _vm.backgroundColor,
                                        height: `${
                                          _vm.maxImgHeight -
                                          showOption.list.find(
                                            (option) => option.checked
                                          ).allImgHeight
                                        }px`,
                                      },
                                    })
                                  : _vm._e(),
                                showOption.list.find((option) => option.checked)
                                  .manuscriptList &&
                                showOption.list.find((option) => option.checked)
                                  .manuscriptList.length
                                  ? _c(
                                      "div",
                                      { staticClass: "show-content" },
                                      _vm._l(
                                        showOption.list.find(
                                          (option) => option.checked
                                        ).manuscriptList,
                                        function (item, itemIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.id + "page",
                                              staticClass: "show-page-content",
                                              style: {
                                                width: "100%",
                                                position: "relative",
                                                height:
                                                  item.imgHeight &&
                                                  item.imgHeight + "px",
                                                overflow: "initial",
                                                display: "block",
                                              },
                                              attrs: { id: item.id + "page" },
                                              on: {
                                                click: (e) => {
                                                  _vm.showClick(
                                                    e,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ),
                                                    itemIndex
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              item.annotations &&
                                              item.imgHeight &&
                                              _vm.isShow &&
                                              _vm.hasLocal &&
                                              item.annotations.filter(
                                                (local) =>
                                                  local.annotationKind == 1 &&
                                                  (_vm.checkedStages.some(
                                                    (c) =>
                                                      c == local.targetStage
                                                  ) ||
                                                    !local.targetStage) &&
                                                  (!_vm.radioValue ||
                                                    (_vm.radioValue == 2 &&
                                                      (local.updateStatus ==
                                                        2 ||
                                                        !local.updateStatus)) ||
                                                    (_vm.radioValue == 1 &&
                                                      local.updateStatus == 1))
                                              ).length &&
                                              !_vm.isDownLoad &&
                                              !_vm.selectedPlatte &&
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).num
                                                ? _vm._l(
                                                    item.annotations.filter(
                                                      (local) =>
                                                        local.annotationKind ==
                                                          1 &&
                                                        (_vm.checkedStages.some(
                                                          (c) =>
                                                            c ==
                                                            local.targetStage
                                                        ) ||
                                                          !local.targetStage)
                                                    ),
                                                    function (local, aIdx) {
                                                      return _c(
                                                        "vue-draggable-resizable",
                                                        {
                                                          key:
                                                            aIdx + "imgLocal",
                                                          staticClass:
                                                            "ocr-show-page-text",
                                                          style: {
                                                            position:
                                                              "absolute",
                                                            border: "none",
                                                          },
                                                          attrs: {
                                                            id:
                                                              "ocr-show-page-text-" +
                                                              local.id,
                                                            draggable:
                                                              _vm.isSelectedLocal ||
                                                              !_vm.changeAll ||
                                                              !local.selected
                                                                ? false
                                                                : true,
                                                            resizable: false,
                                                            parent: true,
                                                            x: _vm.getAnnotationStyle(
                                                              local,
                                                              showOption.list.find(
                                                                (option) =>
                                                                  option.checked
                                                              ),
                                                              "right"
                                                            ).x,
                                                            y: _vm.getAnnotationStyle(
                                                              local,
                                                              showOption.list.find(
                                                                (option) =>
                                                                  option.checked
                                                              ),
                                                              "right"
                                                            ).y,
                                                            w: _vm.getAnnotationStyle(
                                                              local,
                                                              showOption.list.find(
                                                                (option) =>
                                                                  option.checked
                                                              ),
                                                              "right"
                                                            ).w,
                                                            h: _vm.getAnnotationStyle(
                                                              local,
                                                              showOption.list.find(
                                                                (option) =>
                                                                  option.checked
                                                              ),
                                                              "right"
                                                            ).h,
                                                            z: local.selected
                                                              ? 98
                                                              : 97,
                                                          },
                                                          on: {
                                                            deactivated:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onDeactivatedAnnotation(
                                                                  local
                                                                )
                                                              },
                                                            dragging: (
                                                              left,
                                                              top
                                                            ) =>
                                                              _vm.dragging(
                                                                left,
                                                                top,
                                                                local
                                                              ),
                                                            dragstop: (
                                                              left,
                                                              top
                                                            ) => {
                                                              _vm.onDragstopAnnotation(
                                                                left,
                                                                top,
                                                                local,
                                                                showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ),
                                                                item,
                                                                itemIndex
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                id: local.id
                                                                  ? "local-" +
                                                                    local.id
                                                                  : "newLocal-" +
                                                                    aIdx,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    _vm.onActivatedAnnotation(
                                                                      local,
                                                                      showOption.list.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.checked
                                                                      )
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("local-two", {
                                                                attrs: {
                                                                  theme:
                                                                    "filled",
                                                                  size:
                                                                    local.size ||
                                                                    30,
                                                                  fill:
                                                                    local.selected &&
                                                                    _vm.selectLocalId ==
                                                                      local.id
                                                                      ? _vm.color
                                                                      : "orange",
                                                                  strokeWidth: 3,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          local.selected
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "local-content",
                                                                  style:
                                                                    _vm.getLocalActionStyle(
                                                                      item,
                                                                      showOption.list.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.checked
                                                                      ),
                                                                      "left",
                                                                      local
                                                                    ),
                                                                  attrs: {
                                                                    "click.stop":
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    mouseover:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        _vm.isSelectedLocal = true
                                                                      },
                                                                    mouseleave:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        _vm.isSelectedLocal = false
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  local.toStagesLeaveMessage &&
                                                                  local
                                                                    .toStagesLeaveMessage
                                                                    .length &&
                                                                  ((
                                                                    local.id +
                                                                    ""
                                                                  ).indexOf(
                                                                    "newLocal"
                                                                  ) > -1 ||
                                                                    local
                                                                      .initUser
                                                                      .userId ==
                                                                      _vm
                                                                        .userInfo
                                                                        .id)
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-to-stage",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "font-size":
                                                                                    "16px",
                                                                                  height:
                                                                                    "100%",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                          To:\n                        "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "10px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a-radio-group",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.isAutoSave ||
                                                                                        _vm.contentEdit,
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.toStageChange(
                                                                                          local,
                                                                                          item,
                                                                                          itemIndex,
                                                                                          showOption.list.find(
                                                                                            (
                                                                                              option
                                                                                            ) =>
                                                                                              option.checked
                                                                                          ),
                                                                                          1
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        local.targetStage,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            local,
                                                                                            "targetStage",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "local.targetStage",
                                                                                    },
                                                                                },
                                                                                _vm._l(
                                                                                  local.toStagesLeaveMessage.filter(
                                                                                    (
                                                                                      s
                                                                                    ) => {
                                                                                      return (
                                                                                        (_vm.changeAll &&
                                                                                          !_vm.contentEdit) ||
                                                                                        s.stageName ==
                                                                                          local.targetStage
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  function (
                                                                                    stage
                                                                                  ) {
                                                                                    return _c(
                                                                                      "a-radio",
                                                                                      {
                                                                                        key: stage.stageName,
                                                                                        attrs:
                                                                                          {
                                                                                            value:
                                                                                              stage.stageName,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                              " +
                                                                                            _vm._s(
                                                                                              stage.stageName
                                                                                            ) +
                                                                                            "\n                            "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content-list",
                                                                      style: {
                                                                        padding:
                                                                          local.commentData &&
                                                                          local
                                                                            .commentData
                                                                            .length
                                                                            ? ""
                                                                            : "0",
                                                                      },
                                                                    },
                                                                    [
                                                                      false
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "local-content-error-title",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                          软硬伤标记\n                        "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      false
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "local-content-error-select",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      display:
                                                                                        "flex",
                                                                                      "align-items":
                                                                                        "center",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      class:
                                                                                        local.injuryType ==
                                                                                        2
                                                                                          ? "platte-error platte-error-select"
                                                                                          : "platte-error",
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "red",
                                                                                          width:
                                                                                            "28px",
                                                                                          "padding-left":
                                                                                            "6px",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            _vm.changeAll &&
                                                                                            !_vm.contentEdit
                                                                                              ? _vm.selectLocalError(
                                                                                                  2,
                                                                                                  local,
                                                                                                  item,
                                                                                                  itemIndex,
                                                                                                  showOption.list.find(
                                                                                                    (
                                                                                                      option
                                                                                                    ) =>
                                                                                                      option.checked
                                                                                                  )
                                                                                                )
                                                                                              : () => {}
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "硬"
                                                                                          ),
                                                                                          local.injuryType ==
                                                                                          2
                                                                                            ? _c(
                                                                                                "a-icon",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      type: "check-circle",
                                                                                                      theme:
                                                                                                        "filled",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  local.targetStage !=
                                                                                    "监制" &&
                                                                                  _vm
                                                                                    .taskDetail
                                                                                    .stageName !=
                                                                                    "监制确认"
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          class:
                                                                                            local.injuryType ==
                                                                                            1
                                                                                              ? "platte-error platte-error-select"
                                                                                              : "platte-error",
                                                                                          staticStyle:
                                                                                            {
                                                                                              color:
                                                                                                "orange",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                _vm.changeAll &&
                                                                                                !_vm.contentEdit
                                                                                                  ? _vm.selectLocalError(
                                                                                                      1,
                                                                                                      local,
                                                                                                      item,
                                                                                                      itemIndex,
                                                                                                      showOption.list.find(
                                                                                                        (
                                                                                                          option
                                                                                                        ) =>
                                                                                                          option.checked
                                                                                                      )
                                                                                                    )
                                                                                                  : () => {}
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "软"
                                                                                              ),
                                                                                              local.injuryType ==
                                                                                              1
                                                                                                ? _c(
                                                                                                    "a-icon",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "check-circle",
                                                                                                          theme:
                                                                                                            "filled",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  local.targetStage !=
                                                                                    "监制" &&
                                                                                  local.targetStage !=
                                                                                    "校对" &&
                                                                                  _vm
                                                                                    .taskDetail
                                                                                    .stageName !=
                                                                                    "监制确认"
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          class:
                                                                                            local.injuryType ==
                                                                                            4
                                                                                              ? "platte-error platte-error-select"
                                                                                              : "platte-error",
                                                                                          staticStyle:
                                                                                            {
                                                                                              color:
                                                                                                "red",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                _vm.changeAll &&
                                                                                                !_vm.contentEdit
                                                                                                  ? _vm.selectLocalError(
                                                                                                      4,
                                                                                                      local,
                                                                                                      item,
                                                                                                      itemIndex,
                                                                                                      showOption.list.find(
                                                                                                        (
                                                                                                          option
                                                                                                        ) =>
                                                                                                          option.checked
                                                                                                      )
                                                                                                    )
                                                                                                  : () => {}
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  width:
                                                                                                    "35px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "超硬"
                                                                                              ),
                                                                                              local.injuryType ==
                                                                                              4
                                                                                                ? _c(
                                                                                                    "a-icon",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "check-circle",
                                                                                                          theme:
                                                                                                            "filled",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  local.targetStage !=
                                                                                    "监制" &&
                                                                                  local.targetStage !=
                                                                                    "校对" &&
                                                                                  _vm
                                                                                    .taskDetail
                                                                                    .stageName !=
                                                                                    "监制确认"
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          class:
                                                                                            local.injuryType ==
                                                                                            5
                                                                                              ? "platte-error platte-error-select"
                                                                                              : "platte-error",
                                                                                          staticStyle:
                                                                                            {
                                                                                              color:
                                                                                                "orange",
                                                                                              "margin-left":
                                                                                                "8px",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                _vm.changeAll &&
                                                                                                !_vm.contentEdit
                                                                                                  ? _vm.selectLocalError(
                                                                                                      5,
                                                                                                      local,
                                                                                                      item,
                                                                                                      itemIndex,
                                                                                                      showOption.list.find(
                                                                                                        (
                                                                                                          option
                                                                                                        ) =>
                                                                                                          option.checked
                                                                                                      )
                                                                                                    )
                                                                                                  : () => {}
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  width:
                                                                                                    "35px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "超软"
                                                                                              ),
                                                                                              local.injuryType ==
                                                                                              5
                                                                                                ? _c(
                                                                                                    "a-icon",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "check-circle",
                                                                                                          theme:
                                                                                                            "filled",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              ),
                                                                              local.injuryType
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "padding-left":
                                                                                            "9px",
                                                                                          "padding-bottom":
                                                                                            "12px",
                                                                                        },
                                                                                      on: {
                                                                                        mouseover:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            _vm.isSelectedLocal = true
                                                                                          },
                                                                                        mouseleave:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            _vm.isSelectedLocal = false
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                            " +
                                                                                          _vm._s(
                                                                                            local.injuryReason
                                                                                          ) +
                                                                                          "\n                          "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      local
                                                                        .commentData
                                                                        .length
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "local-content-error-list",
                                                                            },
                                                                            _vm._l(
                                                                              local.commentData,
                                                                              function (
                                                                                localItem,
                                                                                localItemIndex
                                                                              ) {
                                                                                return _c(
                                                                                  "div",
                                                                                  {
                                                                                    key: localItem.id,
                                                                                    staticClass:
                                                                                      "local-content-error-list-item",
                                                                                    style:
                                                                                      {
                                                                                        paddingBottom:
                                                                                          localItemIndex !=
                                                                                          local
                                                                                            .commentData
                                                                                            .length -
                                                                                            1
                                                                                            ? "5px"
                                                                                            : "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-error-list-item-top",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "local-content-error-list-item-top-left",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "img",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    src: localItem.avatar,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "local-content-error-list-item-top-right",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "font-size":
                                                                                                      "12px",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    localItem.userName ||
                                                                                                      "用户名"
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "font-size":
                                                                                                      "12px",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    localItem.createTimeStr
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-error-list-item-bottom",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "local-content-error-list-item-bottom-left",
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "local-content-error-list-item-bottom-right",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "show-local-content",
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "font-size":
                                                                                                      "12px",
                                                                                                    color:
                                                                                                      "black",
                                                                                                    "min-height":
                                                                                                      "18px",
                                                                                                    "user-select":
                                                                                                      "text",
                                                                                                  },
                                                                                                domProps:
                                                                                                  {
                                                                                                    innerHTML:
                                                                                                      _vm._s(
                                                                                                        localItem.content ||
                                                                                                          "无说明"
                                                                                                      ),
                                                                                                  },
                                                                                                on: {
                                                                                                  copy: _vm.textCopy,
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                  local.selected
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-input",
                                                                          on: {
                                                                            mouseover:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                _vm.isSelectedLocal = true
                                                                              },
                                                                            mouseleave:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                _vm.isSelectedLocal = false
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm.changeAll &&
                                                                          !_vm.contentEdit &&
                                                                          _vm.selectLocalId ==
                                                                            local.id
                                                                            ? _c(
                                                                                "j-editor",
                                                                                {
                                                                                  ref: "localInput",
                                                                                  refInFor: true,
                                                                                  attrs:
                                                                                    {
                                                                                      placeholder:
                                                                                        "ALT+回车换行，回车提交",
                                                                                      maxWidth:
                                                                                        "50px",
                                                                                      toolbar:
                                                                                        [],
                                                                                    },
                                                                                  on: {
                                                                                    mouseover:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.stopPropagation()
                                                                                        _vm.isSelectedLocal = true
                                                                                      },
                                                                                    postValue:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.saveLoacal(
                                                                                          local,
                                                                                          item,
                                                                                          itemIndex,
                                                                                          showOption.list.find(
                                                                                            (
                                                                                              option
                                                                                            ) =>
                                                                                              option.checked
                                                                                          )
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm.localValue,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.localValue =
                                                                                            $$v
                                                                                        },
                                                                                      expression:
                                                                                        "localValue",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          local.selected &&
                                                          _vm.selectLocalId ==
                                                            local.id &&
                                                          _vm.changeAll &&
                                                          (_vm.isLocalZhongShen ||
                                                            _vm.checkPermission(
                                                              "fatchStraw:localList:dele"
                                                            ) ||
                                                            (local.initUser &&
                                                              _vm.userInfo.id ==
                                                                local.initUser
                                                                  .userId) ||
                                                            (
                                                              local.id + ""
                                                            ).indexOf(
                                                              "newLocal"
                                                            ) > -1 ||
                                                            (local.targetStage ==
                                                              "终审" &&
                                                              local.createStage ==
                                                                "监制" &&
                                                              _vm.userInfo.positions.some(
                                                                (item) =>
                                                                  item.name ==
                                                                  "终审"
                                                              )))
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "ocr-show-item-title-local",
                                                                  style:
                                                                    _vm.getActionCloseStyle(
                                                                      showOption.list.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.checked
                                                                      ).num
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span"),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "a-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "close",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.deleteLocal(
                                                                                  local,
                                                                                  item,
                                                                                  aIdx,
                                                                                  item.annotations
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  )
                                                : _vm._e(),
                                              item.annotations &&
                                              !_vm.selectedPlatte &&
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).num
                                                ? [
                                                    _vm._l(
                                                      item.annotations.filter(
                                                        (local) =>
                                                          local.annotationKind !=
                                                            1 &&
                                                          local.annotationKind !=
                                                            3 &&
                                                          (_vm.checkedStages.some(
                                                            (c) =>
                                                              c ==
                                                              local.targetStage
                                                          ) ||
                                                            !local.targetStage) &&
                                                          (!_vm.radioValue ||
                                                            (_vm.radioValue ==
                                                              2 &&
                                                              (local.updateStatus ==
                                                                2 ||
                                                                !local.updateStatus)) ||
                                                            (_vm.radioValue ==
                                                              1 &&
                                                              local.updateStatus ==
                                                                1))
                                                      ),
                                                      function (local) {
                                                        return _c("img", {
                                                          key: local.id,
                                                          style: {
                                                            width: local
                                                              .position.width
                                                              ? local.position
                                                                  .width *
                                                                  showOption.list.find(
                                                                    (option) =>
                                                                      option.checked
                                                                  ).num +
                                                                "px"
                                                              : "100%",
                                                            height: "auto",
                                                            position:
                                                              "absolute",
                                                            opacity:
                                                              _vm.selectLocalId ==
                                                                local.id ||
                                                              _vm.selectLocalId ==
                                                                -1
                                                                ? "1"
                                                                : "0.3",
                                                            top:
                                                              local.position.y *
                                                                showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num +
                                                              "px",
                                                            left: 0,
                                                          },
                                                          attrs: {
                                                            src: local.imageUrl,
                                                          },
                                                          on: {
                                                            dragstart:
                                                              function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                              },
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    _vm._l(
                                                      item.annotations.filter(
                                                        (local) =>
                                                          local.annotationKind ==
                                                            3 &&
                                                          (_vm.checkedStages.some(
                                                            (c) =>
                                                              c ==
                                                              local.targetStage
                                                          ) ||
                                                            !local.targetStage) &&
                                                          (!_vm.radioValue ||
                                                            (_vm.radioValue ==
                                                              2 &&
                                                              (local.updateStatus ==
                                                                2 ||
                                                                !local.updateStatus)) ||
                                                            (_vm.radioValue ==
                                                              1 &&
                                                              local.updateStatus ==
                                                                1))
                                                      ),
                                                      function (local) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: local.id,
                                                            style: {
                                                              width:
                                                                local.position
                                                                  .width *
                                                                  showOption.list.find(
                                                                    (option) =>
                                                                      option.checked
                                                                  ).num +
                                                                "px",
                                                              height:
                                                                local.position
                                                                  .height *
                                                                  showOption.list.find(
                                                                    (option) =>
                                                                      option.checked
                                                                  ).num +
                                                                "px",
                                                              position:
                                                                "absolute",
                                                              border:
                                                                _vm.selectLocalId ==
                                                                local.id
                                                                  ? "2px solid red"
                                                                  : "2px solid orange",
                                                              top:
                                                                local.position
                                                                  .y *
                                                                  showOption.list.find(
                                                                    (option) =>
                                                                      option.checked
                                                                  ).num +
                                                                "px",
                                                              left:
                                                                local.position
                                                                  .x *
                                                                  showOption.list.find(
                                                                    (option) =>
                                                                      option.checked
                                                                  ).num +
                                                                "px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                _vm.onActivatedAnnotation(
                                                                  local,
                                                                  showOption.list.find(
                                                                    (option) =>
                                                                      option.checked
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            local.selected &&
                                                            _vm.selectLocalId ==
                                                              local.id
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "local-content",
                                                                    style:
                                                                      _vm.getLocalActionStyle(
                                                                        item,
                                                                        showOption.list.find(
                                                                          (
                                                                            option
                                                                          ) =>
                                                                            option.checked
                                                                        ),
                                                                        "left",
                                                                        local
                                                                      ),
                                                                    on: {
                                                                      mouseover:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          _vm.isSelectedLocal = true
                                                                        },
                                                                      mouseleave:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          _vm.isSelectedLocal = false
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "local-content-list",
                                                                        style: {
                                                                          padding:
                                                                            local.commentData &&
                                                                            local
                                                                              .commentData
                                                                              .length
                                                                              ? "0"
                                                                              : "0",
                                                                        },
                                                                      },
                                                                      [
                                                                        local.toStagesAnnotation &&
                                                                        local
                                                                          .toStagesAnnotation
                                                                          .length &&
                                                                        ((
                                                                          local.id +
                                                                          ""
                                                                        ).indexOf(
                                                                          "newLocal"
                                                                        ) >
                                                                          -1 ||
                                                                          local
                                                                            .initUser
                                                                            .userId ==
                                                                            _vm
                                                                              .userInfo
                                                                              .id)
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "local-content-to-stage",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "16px",
                                                                                        height:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                            To:\n                          "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-left":
                                                                                          "10px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "a-radio-group",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            disabled:
                                                                                              !_vm.changeAll ||
                                                                                              _vm.isAutoSave ||
                                                                                              _vm.contentEdit,
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.toStageChange(
                                                                                                local,
                                                                                                item,
                                                                                                itemIndex,
                                                                                                showOption.list.find(
                                                                                                  (
                                                                                                    option
                                                                                                  ) =>
                                                                                                    option.checked
                                                                                                ),
                                                                                                3
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              local.targetStage,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  local,
                                                                                                  "targetStage",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "local.targetStage",
                                                                                          },
                                                                                      },
                                                                                      _vm._l(
                                                                                        local.toStagesAnnotation.filter(
                                                                                          (
                                                                                            s
                                                                                          ) => {
                                                                                            return (
                                                                                              (_vm.changeAll &&
                                                                                                !_vm.contentEdit) ||
                                                                                              s.stageName ==
                                                                                                local.targetStage
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                        function (
                                                                                          stage
                                                                                        ) {
                                                                                          return _c(
                                                                                            "a-radio",
                                                                                            {
                                                                                              key: stage.stageName,
                                                                                              attrs:
                                                                                                {
                                                                                                  value:
                                                                                                    stage.stageName,
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                " +
                                                                                                  _vm._s(
                                                                                                    stage.stageName
                                                                                                  ) +
                                                                                                  "\n                              "
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        local.toStagesAnnotation.find(
                                                                          (
                                                                            stage
                                                                          ) =>
                                                                            stage.stageName ==
                                                                            local.targetStage
                                                                        ) &&
                                                                        local.toStagesAnnotation.find(
                                                                          (
                                                                            stage
                                                                          ) =>
                                                                            stage.stageName ==
                                                                            local.targetStage
                                                                        )
                                                                          .errorDict
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "local-content-error-title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                          软硬伤标记\n                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        local.toStagesAnnotation.find(
                                                                          (
                                                                            stage
                                                                          ) =>
                                                                            stage.stageName ==
                                                                            local.targetStage
                                                                        ) &&
                                                                        local.toStagesAnnotation.find(
                                                                          (
                                                                            stage
                                                                          ) =>
                                                                            stage.stageName ==
                                                                            local.targetStage
                                                                        )
                                                                          .errorDict
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "local-content-error-select",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        display:
                                                                                          "flex",
                                                                                        "align-items":
                                                                                          "center",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        class:
                                                                                          local.injuryType ==
                                                                                          0
                                                                                            ? "platte-error platte-error-select"
                                                                                            : "platte-error",
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "28px",
                                                                                            "padding-left":
                                                                                              "6px",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                              _vm.changeAll &&
                                                                                              !_vm.contentEdit
                                                                                                ? _vm.selectLocalError(
                                                                                                    0,
                                                                                                    local,
                                                                                                    item,
                                                                                                    itemIndex,
                                                                                                    showOption.list.find(
                                                                                                      (
                                                                                                        option
                                                                                                      ) =>
                                                                                                        option.checked
                                                                                                    ),
                                                                                                    3
                                                                                                  )
                                                                                                : () => {}
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "无"
                                                                                            ),
                                                                                            !local.injuryType
                                                                                              ? _c(
                                                                                                  "a-icon",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "check-circle",
                                                                                                        theme:
                                                                                                          "filled",
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._l(
                                                                                      local.toStagesAnnotation.find(
                                                                                        (
                                                                                          stage
                                                                                        ) =>
                                                                                          stage.stageName ==
                                                                                          local.targetStage
                                                                                      )
                                                                                        .errorDict,
                                                                                      function (
                                                                                        error,
                                                                                        errorIndex
                                                                                      ) {
                                                                                        return _c(
                                                                                          "div",
                                                                                          {
                                                                                            key: error.id,
                                                                                            class:
                                                                                              local.injuryType ==
                                                                                              error.type
                                                                                                ? "platte-error platte-error-select"
                                                                                                : "platte-error",
                                                                                            style:
                                                                                              {
                                                                                                color:
                                                                                                  error.dictName.indexOf(
                                                                                                    "硬"
                                                                                                  ) >
                                                                                                    -1 ||
                                                                                                  error.dictName.indexOf(
                                                                                                    "错翻"
                                                                                                  ) >
                                                                                                    -1
                                                                                                    ? "red"
                                                                                                    : "orange",
                                                                                                justifyContent:
                                                                                                  "center",
                                                                                                width:
                                                                                                  error
                                                                                                    .dictName
                                                                                                    .length ==
                                                                                                  1
                                                                                                    ? "28px"
                                                                                                    : "40px",
                                                                                                paddingLeft:
                                                                                                  !errorIndex
                                                                                                    ? "2px"
                                                                                                    : "",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.stopPropagation()
                                                                                                  _vm.changeAll &&
                                                                                                  !_vm.contentEdit
                                                                                                    ? _vm.selectLocalError(
                                                                                                        error.type,
                                                                                                        local,
                                                                                                        item,
                                                                                                        itemIndex,
                                                                                                        showOption.list.find(
                                                                                                          (
                                                                                                            option
                                                                                                          ) =>
                                                                                                            option.checked
                                                                                                        ),
                                                                                                        3
                                                                                                      )
                                                                                                    : () => {}
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                style:
                                                                                                  {
                                                                                                    width:
                                                                                                      error
                                                                                                        .dictName
                                                                                                        .length >
                                                                                                      1
                                                                                                        ? "40px"
                                                                                                        : "",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    error.dictName
                                                                                                  )
                                                                                                ),
                                                                                                local.injuryType ==
                                                                                                error.type
                                                                                                  ? _c(
                                                                                                      "a-icon",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            type: "check-circle",
                                                                                                            theme:
                                                                                                              "filled",
                                                                                                          },
                                                                                                      }
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  2
                                                                                ),
                                                                                local.injuryType
                                                                                  ? _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "padding-left":
                                                                                              "9px",
                                                                                            "padding-bottom":
                                                                                              "12px",
                                                                                          },
                                                                                        on: {
                                                                                          mouseover:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                              _vm.isSelectedLocal = true
                                                                                            },
                                                                                          mouseleave:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                              _vm.isSelectedLocal = false
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                            " +
                                                                                            _vm._s(
                                                                                              local.injuryReason
                                                                                            ) +
                                                                                            "\n                          "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        (local.commentData &&
                                                                          local
                                                                            .commentData
                                                                            .length >
                                                                            1) ||
                                                                        (local
                                                                          .commentData
                                                                          .length ==
                                                                          1 &&
                                                                          local
                                                                            .commentData[0]
                                                                            .content)
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "local-content-error-list",
                                                                              },
                                                                              _vm._l(
                                                                                local.commentData,
                                                                                function (
                                                                                  localItem,
                                                                                  localItemIndex
                                                                                ) {
                                                                                  return _c(
                                                                                    "div",
                                                                                    {
                                                                                      key: localItem.id,
                                                                                      staticClass:
                                                                                        "local-content-error-list-item",
                                                                                      style:
                                                                                        {
                                                                                          paddingBottom:
                                                                                            localItemIndex !=
                                                                                            local
                                                                                              .commentData
                                                                                              .length -
                                                                                              1
                                                                                              ? "5px"
                                                                                              : "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "local-content-error-list-item-top",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-content-error-list-item-top-left",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "img",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      width:
                                                                                                        "20px",
                                                                                                      height:
                                                                                                        "20px",
                                                                                                      "border-radius":
                                                                                                        "50%",
                                                                                                    },
                                                                                                  attrs:
                                                                                                    {
                                                                                                      src: localItem.avatar,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-content-error-list-item-top-right",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "font-size":
                                                                                                        "12px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      localItem.userName ||
                                                                                                        "用户名"
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "font-size":
                                                                                                        "12px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      localItem.createTimeStr
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "local-content-error-list-item-bottom",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-content-error-list-item-bottom-left",
                                                                                            }
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-content-error-list-item-bottom-right",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "show-local-content",
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "font-size":
                                                                                                        "12px",
                                                                                                      color:
                                                                                                        "black",
                                                                                                      "min-height":
                                                                                                        "18px",
                                                                                                      "user-select":
                                                                                                        "text",
                                                                                                    },
                                                                                                  domProps:
                                                                                                    {
                                                                                                      innerHTML:
                                                                                                        _vm._s(
                                                                                                          localItem.content ||
                                                                                                            "无说明"
                                                                                                        ),
                                                                                                    },
                                                                                                  on: {
                                                                                                    copy: _vm.textCopy,
                                                                                                  },
                                                                                                }
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              0
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "local-content-input",
                                                                        on: {
                                                                          mouseover:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              _vm.isSelectedLocal = true
                                                                            },
                                                                          mouseleave:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              _vm.isSelectedLocal = false
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm.changeAll &&
                                                                        !_vm.contentEdit
                                                                          ? _c(
                                                                              "j-editor",
                                                                              {
                                                                                ref: "localInput",
                                                                                refInFor: true,
                                                                                attrs:
                                                                                  {
                                                                                    placeholder:
                                                                                      "ALT+回车换行，回车提交",
                                                                                    maxWidth:
                                                                                      "50px",
                                                                                    toolbar:
                                                                                      [],
                                                                                  },
                                                                                on: {
                                                                                  mouseover:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      $event.stopPropagation()
                                                                                      _vm.isSelectedLocal = true
                                                                                    },
                                                                                  postValue:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.saveLoacal(
                                                                                        local,
                                                                                        item,
                                                                                        itemIndex,
                                                                                        showOption.list.find(
                                                                                          (
                                                                                            option
                                                                                          ) =>
                                                                                            option.checked
                                                                                        ),
                                                                                        3
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm.localValue,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.localValue =
                                                                                          $$v
                                                                                      },
                                                                                    expression:
                                                                                      "localValue",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.diffTitle &&
                                              !_vm.selectedPlatte &&
                                              !_vm.isDownLoad &&
                                              _vm.diffTitle ==
                                                showOption.list.find(
                                                  (option) => option.checked
                                                ).title &&
                                              _vm.diffs.length &&
                                              _vm.diffs.find(
                                                (diff) =>
                                                  diff.fileName == item.fileName
                                              ) &&
                                              _vm.diffs.find(
                                                (diff) =>
                                                  diff.fileName == item.fileName
                                              ).rectangles != null
                                                ? [
                                                    _vm._l(
                                                      _vm.diffs.find(
                                                        (diff) =>
                                                          diff.fileName ==
                                                          item.fileName
                                                      ).rectangles,
                                                      function (
                                                        diffItem,
                                                        diffIndex
                                                      ) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              key:
                                                                diffIndex +
                                                                "-diff-div",
                                                              ref:
                                                                diffItem
                                                                  .minPoint.y +
                                                                "" +
                                                                diffIndex,
                                                              refInFor: true,
                                                              class:
                                                                _vm.selectedDiffIdx &&
                                                                _vm.diffs[
                                                                  _vm.selectedDiffIdx.split(
                                                                    "-"
                                                                  )[0]
                                                                ].fileName ==
                                                                  item.fileName &&
                                                                _vm.selectedDiffIdx.split(
                                                                  "-"
                                                                )[1] ==
                                                                  diffIndex
                                                                  ? "diff-div-select"
                                                                  : "diff-div",
                                                              style: `left:${
                                                                diffItem
                                                                  .minPoint.x *
                                                                showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num
                                                              }px;top:${
                                                                diffItem
                                                                  .minPoint.y *
                                                                showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num
                                                              }px;width:${
                                                                (diffItem
                                                                  .maxPoint.x -
                                                                  diffItem
                                                                    .minPoint
                                                                    .x) *
                                                                showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num
                                                              }px;height:${
                                                                (diffItem
                                                                  .maxPoint.y +
                                                                  20 -
                                                                  diffItem
                                                                    .minPoint
                                                                    .y) *
                                                                showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num
                                                              }px;min-width:10px;min-height:10px;${
                                                                _vm.showDiffs ==
                                                                "ON"
                                                                  ? ""
                                                                  : "opacity:0;"
                                                              }${
                                                                !diffItem.isShow
                                                                  ? ""
                                                                  : "opacity:0;"
                                                              }`,
                                                              attrs: {
                                                                id:
                                                                  _vm.diffs.findIndex(
                                                                    (diff) =>
                                                                      diff.fileName ==
                                                                      item.fileName
                                                                  ) +
                                                                  "-" +
                                                                  diffIndex +
                                                                  "-diff-item",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    _vm.selectDiff(
                                                                      diffIndex,
                                                                      _vm.diffs.findIndex(
                                                                        (
                                                                          diff
                                                                        ) =>
                                                                          diff.fileName ==
                                                                          item.fileName
                                                                      )
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm.selectedDiffIdx &&
                                                              showOption.list.find(
                                                                (option) =>
                                                                  option.checked
                                                              ).isLatest &&
                                                              _vm.diffs[
                                                                _vm.selectedDiffIdx.split(
                                                                  "-"
                                                                )[0]
                                                              ].fileName ==
                                                                item.fileName &&
                                                              _vm.selectedDiffIdx.split(
                                                                "-"
                                                              )[1] == diffIndex
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content",
                                                                      style:
                                                                        _vm.getLocalActionStyle(
                                                                          item,
                                                                          showOption.list.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option.checked
                                                                          ),
                                                                          "left",
                                                                          {
                                                                            position:
                                                                              {
                                                                                x: diffItem
                                                                                  .minPoint
                                                                                  .x,
                                                                                y: diffItem
                                                                                  .minPoint
                                                                                  .y,
                                                                              },
                                                                          }
                                                                        ),
                                                                      on: {
                                                                        mouseover:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            _vm.isSelectedLocal = true
                                                                          },
                                                                        mouseleave:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            _vm.isSelectedLocal = false
                                                                          },
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-list",
                                                                          style:
                                                                            {
                                                                              padding:
                                                                                diffItem.commentData &&
                                                                                diffItem
                                                                                  .commentData
                                                                                  .length
                                                                                  ? ""
                                                                                  : "0",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm.toStagesAnnotation &&
                                                                          _vm
                                                                            .toStagesAnnotation
                                                                            .length
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "local-content-to-stage",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "font-size":
                                                                                            "16px",
                                                                                          height:
                                                                                            "100%",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              To:\n                            "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-left":
                                                                                            "10px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "a-radio-group",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              disabled:
                                                                                                !_vm.changeAll ||
                                                                                                _vm.isAutoSave ||
                                                                                                _vm.contentEdit,
                                                                                            },
                                                                                          on: {
                                                                                            change:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.toStageChangeDiff(
                                                                                                  diffItem,
                                                                                                  item,
                                                                                                  itemIndex,
                                                                                                  showOption.list.find(
                                                                                                    (
                                                                                                      option
                                                                                                    ) =>
                                                                                                      option.checked
                                                                                                  ),
                                                                                                  diffIndex
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm.targetStage,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.targetStage =
                                                                                                    $$v
                                                                                                },
                                                                                              expression:
                                                                                                "targetStage",
                                                                                            },
                                                                                        },
                                                                                        _vm._l(
                                                                                          _vm.toStagesAnnotation.filter(
                                                                                            (
                                                                                              s
                                                                                            ) => {
                                                                                              return (
                                                                                                (_vm.changeAll &&
                                                                                                  !_vm.contentEdit) ||
                                                                                                s.stageName ==
                                                                                                  _vm.targetStage
                                                                                              )
                                                                                            }
                                                                                          ),
                                                                                          function (
                                                                                            stage
                                                                                          ) {
                                                                                            return _c(
                                                                                              "a-radio",
                                                                                              {
                                                                                                key: stage.stageName,
                                                                                                attrs:
                                                                                                  {
                                                                                                    value:
                                                                                                      stage.stageName,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                  " +
                                                                                                    _vm._s(
                                                                                                      stage.stageName
                                                                                                    ) +
                                                                                                    "\n                                "
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm.toStagesAnnotation.find(
                                                                            (
                                                                              stage
                                                                            ) =>
                                                                              stage.stageName ==
                                                                              _vm.targetStage
                                                                          ) &&
                                                                          _vm.toStagesAnnotation.find(
                                                                            (
                                                                              stage
                                                                            ) =>
                                                                              stage.stageName ==
                                                                              _vm.targetStage
                                                                          )
                                                                            .errorDict
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "local-content-error-title",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                            软硬伤标记\n                          "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm.toStagesAnnotation.find(
                                                                            (
                                                                              stage
                                                                            ) =>
                                                                              stage.stageName ==
                                                                              _vm.targetStage
                                                                          ) &&
                                                                          _vm.toStagesAnnotation.find(
                                                                            (
                                                                              stage
                                                                            ) =>
                                                                              stage.stageName ==
                                                                              _vm.targetStage
                                                                          )
                                                                            .errorDict
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "local-content-error-select",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          display:
                                                                                            "flex",
                                                                                          "align-items":
                                                                                            "center",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          class:
                                                                                            _vm.localInjuryType ==
                                                                                            0
                                                                                              ? "platte-error platte-error-select"
                                                                                              : "platte-error",
                                                                                          staticStyle:
                                                                                            {
                                                                                              width:
                                                                                                "28px",
                                                                                              "padding-left":
                                                                                                "6px",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                _vm.changeAll &&
                                                                                                !_vm.contentEdit
                                                                                                  ? _vm.selectLocalErrorDiff(
                                                                                                      0,
                                                                                                      diffItem,
                                                                                                      item,
                                                                                                      itemIndex,
                                                                                                      showOption.list.find(
                                                                                                        (
                                                                                                          option
                                                                                                        ) =>
                                                                                                          option.checked
                                                                                                      ),
                                                                                                      diffIndex
                                                                                                    )
                                                                                                  : () => {}
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "无"
                                                                                              ),
                                                                                              !_vm.localInjuryType
                                                                                                ? _c(
                                                                                                    "a-icon",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "check-circle",
                                                                                                          theme:
                                                                                                            "filled",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._l(
                                                                                        _vm.toStagesAnnotation.find(
                                                                                          (
                                                                                            stage
                                                                                          ) =>
                                                                                            stage.stageName ==
                                                                                            _vm.targetStage
                                                                                        )
                                                                                          .errorDict,
                                                                                        function (
                                                                                          error,
                                                                                          errorIndex
                                                                                        ) {
                                                                                          return _c(
                                                                                            "div",
                                                                                            {
                                                                                              key: error.id,
                                                                                              class:
                                                                                                _vm.localInjuryType ==
                                                                                                error.type
                                                                                                  ? "platte-error platte-error-select"
                                                                                                  : "platte-error",
                                                                                              style:
                                                                                                {
                                                                                                  color:
                                                                                                    error.dictName.indexOf(
                                                                                                      "硬"
                                                                                                    ) >
                                                                                                      -1 ||
                                                                                                    error.dictName.indexOf(
                                                                                                      "错翻"
                                                                                                    ) >
                                                                                                      -1
                                                                                                      ? "red"
                                                                                                      : "orange",
                                                                                                  justifyContent:
                                                                                                    "center",
                                                                                                  width:
                                                                                                    error
                                                                                                      .dictName
                                                                                                      .length ==
                                                                                                    1
                                                                                                      ? "28px"
                                                                                                      : "40px",
                                                                                                  paddingLeft:
                                                                                                    !errorIndex
                                                                                                      ? "2px"
                                                                                                      : "",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                    _vm.changeAll &&
                                                                                                    !_vm.contentEdit
                                                                                                      ? _vm.selectLocalErrorDiff(
                                                                                                          error.type,
                                                                                                          diffItem,
                                                                                                          item,
                                                                                                          itemIndex,
                                                                                                          showOption.list.find(
                                                                                                            (
                                                                                                              option
                                                                                                            ) =>
                                                                                                              option.checked
                                                                                                          ),
                                                                                                          diffIndex,
                                                                                                          error.id
                                                                                                        )
                                                                                                      : () => {}
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  style:
                                                                                                    {
                                                                                                      width:
                                                                                                        error
                                                                                                          .dictName
                                                                                                          .length >
                                                                                                        1
                                                                                                          ? "40px"
                                                                                                          : "",
                                                                                                      textAlign:
                                                                                                        "center",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      error.dictName
                                                                                                    )
                                                                                                  ),
                                                                                                  _vm.localInjuryType ==
                                                                                                  error.type
                                                                                                    ? _c(
                                                                                                        "a-icon",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              type: "check-circle",
                                                                                                              theme:
                                                                                                                "filled",
                                                                                                            },
                                                                                                        }
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    2
                                                                                  ),
                                                                                  _vm.localInjuryType
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              flex: "1",
                                                                                            },
                                                                                          on: {
                                                                                            mouseover:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                _vm.isSelectedLocal = true
                                                                                              },
                                                                                            mouseleave:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                _vm.isSelectedLocal = false
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "a-select",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  width:
                                                                                                    "100%",
                                                                                                  position:
                                                                                                    "relative",
                                                                                                  top: "3px",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  dropdownStyle:
                                                                                                    {
                                                                                                      zIndex: 1055,
                                                                                                    },
                                                                                                  disabled:
                                                                                                    !_vm.changeAll,
                                                                                                  placeholder:
                                                                                                    "请选择",
                                                                                                },
                                                                                              on: {
                                                                                                mouseover:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                    _vm.isSelectedLocal = true
                                                                                                  },
                                                                                                mouseleave:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                    _vm.isSelectedLocal = false
                                                                                                  },
                                                                                                change:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    _vm.saveDiffLoacal(
                                                                                                      diffItem,
                                                                                                      item,
                                                                                                      itemIndex,
                                                                                                      showOption.list.find(
                                                                                                        (
                                                                                                          option
                                                                                                        ) =>
                                                                                                          option.checked
                                                                                                      ),
                                                                                                      diffIndex
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    _vm.localInjuryReason,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.localInjuryReason =
                                                                                                        $$v
                                                                                                    },
                                                                                                  expression:
                                                                                                    "localInjuryReason",
                                                                                                },
                                                                                            },
                                                                                            _vm._l(
                                                                                              _vm.dictList,
                                                                                              function (
                                                                                                errorItem
                                                                                              ) {
                                                                                                return _c(
                                                                                                  "a-select-option",
                                                                                                  {
                                                                                                    key: errorItem.id,
                                                                                                    attrs:
                                                                                                      {
                                                                                                        value:
                                                                                                          errorItem.itemText,
                                                                                                      },
                                                                                                    on: {
                                                                                                      mouseover:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          _vm.isSelectedLocal = true
                                                                                                        },
                                                                                                      mouseleave:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          _vm.isSelectedLocal = false
                                                                                                        },
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                  " +
                                                                                                        _vm._s(
                                                                                                          errorItem.itemText
                                                                                                        ) +
                                                                                                        "\n                                "
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              }
                                                                                            ),
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          (diffItem.commentData &&
                                                                            diffItem
                                                                              .commentData
                                                                              .length >
                                                                              1) ||
                                                                          (diffItem.commentData &&
                                                                            diffItem
                                                                              .commentData
                                                                              .length ==
                                                                              1 &&
                                                                            diffItem
                                                                              .commentData[0]
                                                                              .content)
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "local-content-error-list",
                                                                                },
                                                                                _vm._l(
                                                                                  diffItem.commentData,
                                                                                  function (
                                                                                    localItem,
                                                                                    localItemIndex
                                                                                  ) {
                                                                                    return _c(
                                                                                      "div",
                                                                                      {
                                                                                        key: localItem.id,
                                                                                        staticClass:
                                                                                          "local-content-error-list-item",
                                                                                        style:
                                                                                          {
                                                                                            paddingBottom:
                                                                                              localItemIndex !=
                                                                                              diffItem
                                                                                                .commentData
                                                                                                .length -
                                                                                                1
                                                                                                ? "5px"
                                                                                                : "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "local-content-error-list-item-top",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "local-content-error-list-item-top-left",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "img",
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        width:
                                                                                                          "20px",
                                                                                                        height:
                                                                                                          "20px",
                                                                                                        "border-radius":
                                                                                                          "50%",
                                                                                                      },
                                                                                                    attrs:
                                                                                                      {
                                                                                                        src: localItem.avatar,
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "local-content-error-list-item-top-right",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        "font-size":
                                                                                                          "12px",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        localItem.userName ||
                                                                                                          "用户名"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        "font-size":
                                                                                                          "12px",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        localItem.createTimeStr
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "local-content-error-list-item-bottom",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "local-content-error-list-item-bottom-left",
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "local-content-error-list-item-bottom-right",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "show-local-content",
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        "font-size":
                                                                                                          "12px",
                                                                                                        color:
                                                                                                          "black",
                                                                                                        "min-height":
                                                                                                          "18px",
                                                                                                        "user-select":
                                                                                                          "text",
                                                                                                      },
                                                                                                    domProps:
                                                                                                      {
                                                                                                        innerHTML:
                                                                                                          _vm._s(
                                                                                                            localItem.content ||
                                                                                                              "无说明"
                                                                                                          ),
                                                                                                      },
                                                                                                    on: {
                                                                                                      copy: _vm.textCopy,
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-input",
                                                                          on: {
                                                                            mouseover:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                _vm.isSelectedLocal = true
                                                                              },
                                                                            mouseleave:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                _vm.isSelectedLocal = false
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm.changeAll &&
                                                                          !_vm.contentEdit
                                                                            ? _c(
                                                                                "j-editor",
                                                                                {
                                                                                  ref: "diffInput",
                                                                                  refInFor: true,
                                                                                  attrs:
                                                                                    {
                                                                                      placeholder:
                                                                                        "ALT+回车换行，回车提交",
                                                                                      maxWidth:
                                                                                        "50px",
                                                                                      toolbar:
                                                                                        [],
                                                                                    },
                                                                                  on: {
                                                                                    mouseover:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.stopPropagation()
                                                                                        _vm.isSelectedLocal = true
                                                                                      },
                                                                                    postValue:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.saveDiffLoacal(
                                                                                          diffItem,
                                                                                          item,
                                                                                          itemIndex,
                                                                                          showOption.list.find(
                                                                                            (
                                                                                              option
                                                                                            ) =>
                                                                                              option.checked
                                                                                          ),
                                                                                          diffIndex
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm.localValue,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.localValue =
                                                                                            $$v
                                                                                        },
                                                                                      expression:
                                                                                        "localValue",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      }
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).title == "实时" &&
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).num &&
                                              item.textAreas &&
                                              _vm.showTextArea == "ON" &&
                                              _vm.isShow
                                                ? [
                                                    _vm._l(
                                                      item.textAreas.filter(
                                                        (t) => !t.imgUrl
                                                      ),
                                                      function (textArea) {
                                                        return _c(
                                                          "vue-draggable-resizable",
                                                          {
                                                            key: textArea.id,
                                                            staticStyle: {
                                                              border: "none",
                                                            },
                                                            attrs: {
                                                              resizable: false,
                                                              draggable:
                                                                _vm
                                                                  .selectTextAreaData
                                                                  .length ==
                                                                  1 &&
                                                                _vm.selectTextAreaId ==
                                                                  textArea.id
                                                                  ? true
                                                                  : false,
                                                              y:
                                                                textArea
                                                                  .translateTextAreaRegion
                                                                  .y *
                                                                (showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num || 1),
                                                              x:
                                                                textArea
                                                                  .translateTextAreaRegion
                                                                  .x *
                                                                (showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num || 1),
                                                              w:
                                                                textArea.translateTextAreaRegion &&
                                                                textArea
                                                                  .translateTextAreaRegion
                                                                  .width
                                                                  ? textArea
                                                                      .translateTextAreaRegion
                                                                      .width *
                                                                    (showOption.list.find(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option.checked
                                                                    ).num || 1)
                                                                  : 0,
                                                              h:
                                                                textArea
                                                                  .translateTextAreaRegion
                                                                  .height *
                                                                (showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num || 1),
                                                              z: _vm.selectTextAreaData.some(
                                                                (t) =>
                                                                  t.id ==
                                                                  textArea.id
                                                              )
                                                                ? 1
                                                                : 0,
                                                            },
                                                            on: {
                                                              dragstop: (
                                                                left,
                                                                top
                                                              ) =>
                                                                _vm.onDragstop(
                                                                  left,
                                                                  top,
                                                                  textArea,
                                                                  showOption.list.find(
                                                                    (option) =>
                                                                      option.checked
                                                                  ).num || 1,
                                                                  showOption.list.find(
                                                                    (option) =>
                                                                      option.checked
                                                                  )
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ocr-show-item-right",
                                                                style: {
                                                                  overflowY:
                                                                    "visible",
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                  width: "100%",
                                                                  height:
                                                                    "100%",
                                                                },
                                                                attrs: {
                                                                  id:
                                                                    "textArea-" +
                                                                    textArea.id,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.selectTextArea(
                                                                        textArea,
                                                                        item,
                                                                        itemIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ) &&
                                                                showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "right-text-input",
                                                                        style: {
                                                                          cursor:
                                                                            "pointer",
                                                                          border:
                                                                            _vm.selectTextAreaId ==
                                                                            textArea.id
                                                                              ? "4px dashed red"
                                                                              : "",
                                                                          minHeight:
                                                                            textArea
                                                                              .characterSentences
                                                                              .length ==
                                                                              1 &&
                                                                            textArea
                                                                              .characterSentences[0]
                                                                              .characterText ==
                                                                              ""
                                                                              ? textArea
                                                                                  .characterSentences[0]
                                                                                  .fontSize +
                                                                                30 +
                                                                                "px"
                                                                              : "",
                                                                          minWidth:
                                                                            textArea
                                                                              .characterSentences
                                                                              .length ==
                                                                              1 &&
                                                                            textArea
                                                                              .characterSentences[0]
                                                                              .characterText ==
                                                                              ""
                                                                              ? textArea
                                                                                  .characterSentences[0]
                                                                                  .fontSize +
                                                                                30 +
                                                                                "px"
                                                                              : "",
                                                                          writingMode:
                                                                            textArea.textLayout ==
                                                                            2
                                                                              ? "vertical-rl"
                                                                              : "horizontal-tb",
                                                                          textAlign:
                                                                            textArea
                                                                              .characterSentences[0]
                                                                              ? textArea
                                                                                  .characterSentences[0]
                                                                                  .textAlign
                                                                              : "left",
                                                                          lineHeight:
                                                                            textArea
                                                                              .characterSentences[0]
                                                                              ? textArea.characterSentences[0].lineSpacing.toFixed(
                                                                                  2
                                                                                )
                                                                              : 1.15,
                                                                          letterSpacing:
                                                                            "1px",
                                                                          fontSize:
                                                                            textArea
                                                                              .characterSentences
                                                                              .length ==
                                                                              1 &&
                                                                            (textArea
                                                                              .characterSentences[0]
                                                                              .characterText ==
                                                                              "" ||
                                                                              textArea
                                                                                .characterSentences[0]
                                                                                .characterText ==
                                                                                " ")
                                                                              ? textArea
                                                                                  .characterSentences[0]
                                                                                  .fontSize +
                                                                                "px"
                                                                              : "",
                                                                          color:
                                                                            textArea
                                                                              .characterSentences
                                                                              .length ==
                                                                              1 &&
                                                                            (textArea
                                                                              .characterSentences[0]
                                                                              .characterText ==
                                                                              "" ||
                                                                              textArea
                                                                                .characterSentences[0]
                                                                                .characterText ==
                                                                                " ")
                                                                              ? textArea
                                                                                  .characterSentences[0]
                                                                                  .fontColor
                                                                              : "",
                                                                          backgroundColor:
                                                                            textArea.backgroundColor ||
                                                                            "#fff",
                                                                          padding:
                                                                            _vm.getPadding(
                                                                              textArea
                                                                            ),
                                                                          position:
                                                                            "relative",
                                                                          left: _vm.getJustify(
                                                                            textArea,
                                                                            item,
                                                                            showOption.list.find(
                                                                              (
                                                                                option
                                                                              ) =>
                                                                                option.checked
                                                                            )
                                                                          ),
                                                                        },
                                                                        attrs: {
                                                                          id:
                                                                            item.id +
                                                                            "-" +
                                                                            textArea.id +
                                                                            "-" +
                                                                            "editDiv",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        textArea.showTexts,
                                                                        function (
                                                                          p
                                                                        ) {
                                                                          return _c(
                                                                            "p",
                                                                            {
                                                                              key:
                                                                                item.id +
                                                                                "-" +
                                                                                textArea.id +
                                                                                "-" +
                                                                                p.pId,
                                                                              staticStyle:
                                                                                {
                                                                                  margin:
                                                                                    "0",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  id:
                                                                                    item.id +
                                                                                    "-" +
                                                                                    textArea.id +
                                                                                    "-" +
                                                                                    p.pId,
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              p.texts,
                                                                              function (
                                                                                text,
                                                                                textIdx
                                                                              ) {
                                                                                return _c(
                                                                                  "span",
                                                                                  {
                                                                                    key: text.characterSentenceId,
                                                                                    attrs:
                                                                                      {
                                                                                        id:
                                                                                          item.id +
                                                                                          "-" +
                                                                                          textArea.id +
                                                                                          "-" +
                                                                                          text.characterSentenceId,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        class:
                                                                                          text.hasMark &&
                                                                                          textArea.textLayout ==
                                                                                            1
                                                                                            ? "stroke-span stroke-span-hasMark"
                                                                                            : "stroke-span",
                                                                                        style:
                                                                                          _vm.getTextStyle(
                                                                                            text,
                                                                                            textArea,
                                                                                            showOption.list.find(
                                                                                              (
                                                                                                option
                                                                                              ) =>
                                                                                                option.checked
                                                                                            )
                                                                                              .num
                                                                                          ),
                                                                                        attrs:
                                                                                          {
                                                                                            "data-text":
                                                                                              text.phonetic &&
                                                                                              text
                                                                                                .phonetic
                                                                                                .phoneticText,
                                                                                            id: text.characterSentenceId,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "stroke-span-child",
                                                                                            style:
                                                                                              "white-space: pre-wrap;" +
                                                                                              `transform:scale(${text.textWidth},${text.textHeight});` +
                                                                                              `${
                                                                                                textArea.textLayout ==
                                                                                                  2 &&
                                                                                                text.characterText ==
                                                                                                  " "
                                                                                                  ? `height:${
                                                                                                      text.fontSize /
                                                                                                      3
                                                                                                    }px;`
                                                                                                  : ""
                                                                                              }` +
                                                                                              `${
                                                                                                text.characterText ==
                                                                                                  " " &&
                                                                                                !textIdx &&
                                                                                                !textArea
                                                                                                  .translateTextAreaRegion
                                                                                                  .editble
                                                                                                  ? "display:inline-block;width:0;height:0;"
                                                                                                  : ""
                                                                                              }` +
                                                                                              `-webkit-text-stroke: ${
                                                                                                text.strokeWidth *
                                                                                                2
                                                                                              }px ${
                                                                                                text.strokeColor
                                                                                              };user-select:none;`,
                                                                                            attrs:
                                                                                              {
                                                                                                id:
                                                                                                  text.characterSentenceId +
                                                                                                  "-" +
                                                                                                  "text",
                                                                                                "data-text":
                                                                                                  text.strokeWidth &&
                                                                                                  text.characterText !=
                                                                                                    ""
                                                                                                    ? text.characterText
                                                                                                    : "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                text.characterText !=
                                                                                                  ""
                                                                                                  ? text.characterText
                                                                                                  : ""
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._l(
                                                      item.textAreas.filter(
                                                        (t) => t.imgUrl
                                                      ),
                                                      function (textArea) {
                                                        return _c(
                                                          "vue-draggable-resizable",
                                                          {
                                                            key: textArea.id,
                                                            staticStyle: {
                                                              border: "none",
                                                            },
                                                            attrs: {
                                                              resizable: false,
                                                              draggable:
                                                                _vm
                                                                  .selectTextAreaData
                                                                  .length ==
                                                                  1 &&
                                                                _vm.selectTextAreaId ==
                                                                  textArea.id
                                                                  ? true
                                                                  : false,
                                                              y:
                                                                textArea
                                                                  .translateTextAreaRegion
                                                                  .y *
                                                                (showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num || 1),
                                                              x:
                                                                textArea
                                                                  .translateTextAreaRegion
                                                                  .x *
                                                                (showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num || 1),
                                                              w:
                                                                textArea.translateTextAreaRegion &&
                                                                textArea
                                                                  .translateTextAreaRegion
                                                                  .width
                                                                  ? textArea
                                                                      .translateTextAreaRegion
                                                                      .width *
                                                                    (showOption.list.find(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option.checked
                                                                    ).num || 1)
                                                                  : 0,
                                                              h:
                                                                textArea
                                                                  .translateTextAreaRegion
                                                                  .height *
                                                                (showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num || 1),
                                                              z: _vm.selectTextAreaData.some(
                                                                (t) =>
                                                                  t.id ==
                                                                  textArea.id
                                                              )
                                                                ? 1
                                                                : 0,
                                                            },
                                                            on: {
                                                              dragstop: (
                                                                left,
                                                                top
                                                              ) =>
                                                                _vm.onDragstop(
                                                                  left,
                                                                  top,
                                                                  textArea,
                                                                  showOption.list.find(
                                                                    (option) =>
                                                                      option.checked
                                                                  ).num || 1,
                                                                  showOption.list.find(
                                                                    (option) =>
                                                                      option.checked
                                                                  )
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                width: "100%",
                                                                height: "auto",
                                                              },
                                                              attrs: {
                                                                src: item.imgUrl,
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).title == "实时" &&
                                              _vm.rightAnnotationList.length &&
                                              _vm.rightAnnotationList[
                                                _vm.rightAnnotationList.findIndex(
                                                  (a) => a.pageId == item.id
                                                )
                                              ]
                                                ? _vm._l(
                                                    _vm.rightAnnotationList[
                                                      _vm.rightAnnotationList.findIndex(
                                                        (a) =>
                                                          a.pageId == item.id
                                                      )
                                                    ].annotationList,
                                                    function (local, aIdx) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            local.id +
                                                            "-tanrlateLocal",
                                                          style: {
                                                            position:
                                                              "absolute",
                                                            top:
                                                              local.position.y *
                                                                (showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num || 1) +
                                                              "px",
                                                            left:
                                                              local.position.x *
                                                                (showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num || 1) +
                                                              "px",
                                                          },
                                                          attrs: {
                                                            id: local.id
                                                              ? "local-" +
                                                                local.id
                                                              : "newLocal-" +
                                                                aIdx,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.selectOldLocal(
                                                                local
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.checkedToStages.some(
                                                            (toStage) =>
                                                              toStage ==
                                                              local.toStage
                                                          )
                                                            ? _c("local-two", {
                                                                attrs: {
                                                                  theme:
                                                                    "filled",
                                                                  size: 30,
                                                                  fill: local.selected
                                                                    ? _vm.color
                                                                    : "orange",
                                                                  strokeWidth: 3,
                                                                },
                                                                on: {
                                                                  mouseover:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.isLocalDrag = true
                                                                    },
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          local.selected &&
                                                          _vm.checkedToStages.some(
                                                            (toStage) =>
                                                              toStage ==
                                                              local.toStage
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "local-content-old",
                                                                  style: `transform:scale(${
                                                                    showOption.list.find(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option.checked
                                                                    ) &&
                                                                    showOption.list.find(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option.checked
                                                                    ).num
                                                                      ? 1
                                                                      : 1
                                                                  });transform-origin: top left;`,
                                                                },
                                                                [
                                                                  local
                                                                    .commentData
                                                                    .length
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-list",
                                                                          style:
                                                                            {
                                                                              padding:
                                                                                local.commentData &&
                                                                                local
                                                                                  .commentData
                                                                                  .length
                                                                                  ? ""
                                                                                  : "0",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          local.commentData,
                                                                          function (
                                                                            i,
                                                                            cidx
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key: cidx,
                                                                                staticClass:
                                                                                  "local-content-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "local-content-item-top",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-item-top-left",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src: i.avatar,
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-item-top-right",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                " +
                                                                                                _vm._s(
                                                                                                  i.userName
                                                                                                ) +
                                                                                                "\n                              "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                " +
                                                                                                _vm._s(
                                                                                                  i.createTimeStr
                                                                                                ) +
                                                                                                "\n                              "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "local-content-item-bottom show-local-content",
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            i.content
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      copy: _vm.textCopy,
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                : _vm._e(),
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).title == "实时" &&
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).num &&
                                              item.lines &&
                                              _vm.isShow
                                                ? _vm._l(
                                                    item.lines,
                                                    function (i) {
                                                      return _c("SvgLine", {
                                                        key: i.id,
                                                        ref:
                                                          "page-line-" + i.id,
                                                        refInFor: true,
                                                        attrs: {
                                                          startX:
                                                            i.startPosition.x,
                                                          startY:
                                                            i.startPosition.y,
                                                          endX: i.endPosition.x,
                                                          endY: i.endPosition.y,
                                                          width:
                                                            _vm.getLineItemStyle(
                                                              i,
                                                              showOption.list.find(
                                                                (option) =>
                                                                  option.checked
                                                              ).num || 1,
                                                              item
                                                            ).w,
                                                          height:
                                                            _vm.getLineItemStyle(
                                                              i,
                                                              showOption.list.find(
                                                                (option) =>
                                                                  option.checked
                                                              ).num || 1,
                                                              item
                                                            ).h,
                                                          x: _vm.getLineItemStyle(
                                                            i,
                                                            showOption.list.find(
                                                              (option) =>
                                                                option.checked
                                                            ).num || 1,
                                                            item
                                                          ).x,
                                                          y: _vm.getLineItemStyle(
                                                            i,
                                                            showOption.list.find(
                                                              (option) =>
                                                                option.checked
                                                            ).num || 1,
                                                            item
                                                          ).y,
                                                        },
                                                      })
                                                    }
                                                  )
                                                : _vm._e(),
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).title == "原稿" &&
                                              _vm.leftAnnotationList.length &&
                                              _vm.leftAnnotationList[
                                                _vm.leftAnnotationList.findIndex(
                                                  (a) => a.pageId == item.id
                                                )
                                              ]
                                                ? _vm._l(
                                                    _vm.leftAnnotationList[
                                                      _vm.leftAnnotationList.findIndex(
                                                        (a) =>
                                                          a.pageId == item.id
                                                      )
                                                    ].annotationList,
                                                    function (local, aIdx) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            local.id +
                                                            "-tanrlateLocal",
                                                          style: {
                                                            position:
                                                              "absolute",
                                                            top:
                                                              local.position.y *
                                                                showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num ||
                                                              1 + "px",
                                                            left:
                                                              local.position.x *
                                                                showOption.list.find(
                                                                  (option) =>
                                                                    option.checked
                                                                ).num ||
                                                              1 + "px",
                                                          },
                                                          attrs: {
                                                            id: local.id
                                                              ? "local-" +
                                                                local.id
                                                              : "newLocal-" +
                                                                aIdx,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.selectOldLocal(
                                                                local
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("local-two", {
                                                            attrs: {
                                                              theme: "filled",
                                                              size: 30,
                                                              fill: local.selected
                                                                ? _vm.color
                                                                : "orange",
                                                              strokeWidth: 3,
                                                            },
                                                            on: {
                                                              mouseover:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.isLocalDrag = true
                                                                },
                                                            },
                                                          }),
                                                          local.selected
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "local-content-old",
                                                                  style: `transform:scale(${
                                                                    showOption.list.find(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option.checked
                                                                    ) &&
                                                                    showOption.list.find(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option.checked
                                                                    ).num
                                                                      ? 1
                                                                      : 1
                                                                  });transform-origin: top left;`,
                                                                },
                                                                [
                                                                  local
                                                                    .commentData
                                                                    .length
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-list",
                                                                        },
                                                                        _vm._l(
                                                                          local.commentData,
                                                                          function (
                                                                            i,
                                                                            cidx
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key: cidx,
                                                                                staticClass:
                                                                                  "local-content-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "local-content-item-top",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-item-top-left",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src: i.avatar,
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-item-top-right",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                " +
                                                                                                _vm._s(
                                                                                                  i.userName
                                                                                                ) +
                                                                                                "\n                              "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                " +
                                                                                                _vm._s(
                                                                                                  i.createTimeStr
                                                                                                ) +
                                                                                                "\n                              "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "local-content-item-bottom",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        i.content
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                : _vm._e(),
                                              _vm.selectTextAreaData.length >
                                                1 &&
                                              showOption.list.find(
                                                (option) => option.checked
                                              ) &&
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).title == "实时" &&
                                              _vm.getDeleteBorder(
                                                showOption.list.find(
                                                  (option) => option.checked
                                                )
                                              ).idx === itemIndex
                                                ? _c("div", {
                                                    staticClass:
                                                      "delete-border",
                                                    style: _vm.getDeleteBorder(
                                                      showOption.list.find(
                                                        (option) =>
                                                          option.checked
                                                      )
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.selectedPlatte &&
                        showOption.list.find((option) => option.checked)
                          .isLatest &&
                        showOption.list.find((option) => option.checked)
                          .title != "原稿" &&
                        showOption.list.find((option) => option.checked)
                          .title != "实时"
                          ? _c(
                              "div",
                              {
                                staticClass: "canvas-content",
                                style: {
                                  top: showOption.list.find(
                                    (option) => option.checked
                                  )
                                    ? showOption.list.find(
                                        (option) => option.checked
                                      ).scrollTop + "px"
                                    : "",
                                  minHeight: "100%",
                                  width:
                                    _vm.showImgListWidth.length >= 4
                                      ? _vm.showImgListWidth
                                      : "100%",
                                },
                                attrs: { id: "canvas-area-" + showOptionIndex },
                              },
                              [
                                _c(
                                  "v-stage",
                                  {
                                    ref:
                                      "canvas-" +
                                      showOptionIndex +
                                      "-" +
                                      showOption.list.findIndex(
                                        (option) => option.checked
                                      ),
                                    refInFor: true,
                                    attrs: {
                                      id:
                                        "canvas-" +
                                        showOptionIndex +
                                        "-" +
                                        showOption.list.findIndex(
                                          (option) => option.checked
                                        ),
                                      config: _vm.stageSize,
                                    },
                                    on: {
                                      mousedown: (e) =>
                                        _vm.handleMouseDown(
                                          e,
                                          showOption.list.find(
                                            (option) => option.checked
                                          ),
                                          showOptionIndex,
                                          showOption
                                        ),
                                      mousemove: (e) =>
                                        _vm.handleMouseMove(
                                          e,
                                          showOption.list.find(
                                            (option) => option.checked
                                          )
                                        ),
                                      mouseup: (e) =>
                                        _vm.handleMouseUp(
                                          e,
                                          showOption.list.find(
                                            (option) => option.checked
                                          ),
                                          showOptionIndex,
                                          showOption
                                        ),
                                    },
                                  },
                                  [
                                    _c(
                                      "v-layer",
                                      [
                                        _vm._l(
                                          _vm.lines.filter(
                                            (line) =>
                                              line.type ==
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).title
                                          ),
                                          function (line, index) {
                                            return _c("v-line", {
                                              key:
                                                showOptionIndex +
                                                "-" +
                                                index +
                                                "-line",
                                              attrs: { config: line.data },
                                              on: {
                                                transformend: _vm.transformend,
                                                transformstart:
                                                  _vm.transformstart,
                                                mousedown: (e) =>
                                                  _vm.handleDragStart(
                                                    e,
                                                    line,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    line.platteType,
                                                    index,
                                                    showOptionIndex,
                                                    showOption
                                                  ),
                                                mouseup: (e) =>
                                                  _vm.handleDragEnd(
                                                    e,
                                                    line,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    line.platteType,
                                                    showOptionIndex,
                                                    showOption
                                                  ),
                                                mouseover: (e) =>
                                                  _vm.canvasMouseover(
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    e,
                                                    line
                                                  ),
                                                mouseleave: function ($event) {
                                                  _vm.canvasMouseleave(
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj
                                                  )
                                                },
                                              },
                                            })
                                          }
                                        ),
                                        _vm._l(
                                          _vm.rects.filter(
                                            (rect) =>
                                              rect.type ==
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).title
                                          ),
                                          function (rect, index) {
                                            return _c("v-rect", {
                                              key:
                                                showOptionIndex +
                                                "-" +
                                                index +
                                                "-rect",
                                              attrs: { config: rect.data },
                                              on: {
                                                transformend: _vm.transformend,
                                                transformstart:
                                                  _vm.transformstart,
                                                mousedown: (e) =>
                                                  _vm.handleDragStart(
                                                    e,
                                                    rect,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    rect.platteType,
                                                    index,
                                                    showOptionIndex,
                                                    showOption
                                                  ),
                                                mouseup: (e) =>
                                                  _vm.handleDragEnd(
                                                    e,
                                                    rect,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    rect.platteType,
                                                    showOptionIndex,
                                                    showOption
                                                  ),
                                                mouseover: (e) =>
                                                  _vm.canvasMouseover(
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    e
                                                  ),
                                                mouseleave: (e) =>
                                                  _vm.canvasMouseleave(
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj
                                                  ),
                                              },
                                            })
                                          }
                                        ),
                                        _vm._l(
                                          _vm.arrows.filter(
                                            (arrow) =>
                                              arrow.type ==
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).title
                                          ),
                                          function (arrow, index) {
                                            return _c("v-arrow", {
                                              key:
                                                showOptionIndex +
                                                "-" +
                                                index +
                                                "-arrow",
                                              attrs: { config: arrow.data },
                                              on: {
                                                transformend: _vm.transformend,
                                                transformstart:
                                                  _vm.transformstart,
                                                mousedown: (e) =>
                                                  _vm.handleDragStart(
                                                    e,
                                                    arrow,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    arrow.platteType,
                                                    index,
                                                    showOptionIndex,
                                                    showOption
                                                  ),
                                                mouseup: (e) =>
                                                  _vm.handleDragEnd(
                                                    e,
                                                    arrow,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    arrow.platteType,
                                                    showOptionIndex,
                                                    showOption
                                                  ),
                                                mouseover: (e) =>
                                                  _vm.canvasMouseover(
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    e
                                                  ),
                                                mouseleave: (e) =>
                                                  _vm.canvasMouseleave(
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj
                                                  ),
                                              },
                                            })
                                          }
                                        ),
                                        _vm._l(
                                          _vm.rings.filter(
                                            (ring) =>
                                              ring.type ==
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).title
                                          ),
                                          function (ring, index) {
                                            return _c("v-ellipse", {
                                              key:
                                                showOptionIndex +
                                                "-" +
                                                index +
                                                "-ring",
                                              attrs: { config: ring.data },
                                              on: {
                                                transformend: _vm.transformend,
                                                transformstart:
                                                  _vm.transformstart,
                                                mousedown: (e) =>
                                                  _vm.handleDragStart(
                                                    e,
                                                    ring,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    ring.platteType,
                                                    index,
                                                    showOptionIndex,
                                                    showOption
                                                  ),
                                                mouseup: (e) =>
                                                  _vm.handleDragEnd(
                                                    e,
                                                    ring,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    ring.platteType,
                                                    showOptionIndex,
                                                    showOption
                                                  ),
                                                mouseover: (e) =>
                                                  _vm.canvasMouseover(
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    e
                                                  ),
                                                mouseleave: (e) =>
                                                  _vm.canvasMouseleave(
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj
                                                  ),
                                              },
                                            })
                                          }
                                        ),
                                        _vm._l(
                                          _vm.texts.filter(
                                            (text) =>
                                              text.type ==
                                              showOption.list.find(
                                                (option) => option.checked
                                              ).title
                                          ),
                                          function (text, index) {
                                            return _c("v-text", {
                                              key:
                                                showOptionIndex +
                                                "-" +
                                                index +
                                                "-text",
                                              attrs: { config: text.data },
                                              on: {
                                                transformend: _vm.transformend,
                                                transformstart:
                                                  _vm.transformstart,
                                                dblclick: (e) =>
                                                  _vm.textEdit(
                                                    e,
                                                    text,
                                                    showOptionIndex,
                                                    showOption,
                                                    index
                                                  ),
                                                mousedown: (e) =>
                                                  _vm.handleDragStart(
                                                    e,
                                                    text,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    text.platteType,
                                                    index,
                                                    showOptionIndex,
                                                    showOption
                                                  ),
                                                mouseup: (e) =>
                                                  _vm.handleDragEnd(
                                                    e,
                                                    text,
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    text.platteType,
                                                    showOptionIndex,
                                                    showOption
                                                  ),
                                                mouseover: (e) =>
                                                  _vm.canvasMouseover(
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj,
                                                    e
                                                  ),
                                                mouseleave: (e) =>
                                                  _vm.canvasMouseleave(
                                                    showOption.list.find(
                                                      (option) => option.checked
                                                    ).canvasObj
                                                  ),
                                              },
                                            })
                                          }
                                        ),
                                        _c("v-transformer", {
                                          attrs: {
                                            enabledAnchors: [],
                                            padding: 7,
                                            rotateEnabled: false,
                                          },
                                        }),
                                        _c("v-transformer", {
                                          ref: "transformer",
                                          refInFor: true,
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.is_show_mask &&
                                showOption.list.find(
                                  (option) => option.checked
                                ) &&
                                showOption.list.find((option) => option.checked)
                                  .title == "实时",
                              expression:
                                "\n              is_show_mask &&\n                showOption.list.find(option => option.checked) &&\n                showOption.list.find(option => option.checked).title == '实时'\n            ",
                            },
                          ],
                          staticClass: "mask",
                          style:
                            "width:" +
                            _vm.mask_width +
                            "left:" +
                            _vm.mask_left +
                            "height:" +
                            _vm.mask_height +
                            "top:" +
                            _vm.mask_top,
                        }),
                      ]
                    ),
                    showOptionIndex < _vm.showOptions.length - 1
                      ? _c("div", {
                          class:
                            _vm.changeWidthShowOptionIndex == showOptionIndex
                              ? "show-img-content-item-change-width theme-color"
                              : "show-img-content-item-change-width",
                          on: {
                            mousemove: function ($event) {
                              $event.stopPropagation()
                              return ((e) =>
                                _vm.handleItemMouseMove(
                                  e,
                                  showOptionIndex
                                )).apply(null, arguments)
                            },
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleImgMouseDown(showOptionIndex)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                )
              }),
              _c(
                "div",
                {
                  class: _vm.isDragOption
                    ? "push-option push-option-color"
                    : "push-option",
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        drop: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleDrop.apply(null, arguments)
                        },
                        dragenter: function ($event) {
                          $event.preventDefault()
                          return _vm.dragenter.apply(null, arguments)
                        },
                        dragover: function ($event) {
                          $event.preventDefault()
                        },
                        dragleave: _vm.dragleave,
                      },
                    },
                    [_vm._v("\n          展开分栏\n        ")]
                  ),
                ]
              ),
            ],
            2
          ),
          _c(
            "a-menu",
            { attrs: { slot: "overlay" }, slot: "overlay" },
            [
              _c(
                "a-menu-item",
                {
                  key: "1",
                  on: {
                    click: function ($event) {
                      return _vm.rightClick("black")
                    },
                  },
                },
                [_vm._v(" 黑色 ")]
              ),
              _c(
                "a-menu-item",
                {
                  key: "2",
                  on: {
                    click: function ($event) {
                      return _vm.rightClick("#fff")
                    },
                  },
                },
                [_vm._v(" 白色 ")]
              ),
              _c(
                "a-menu-item",
                {
                  key: "3",
                  on: {
                    click: function ($event) {
                      _vm.rightClick("rgb(223,220,221)")
                    },
                  },
                },
                [_vm._v(" 灰色 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectedPlatte
        ? _c(
            "div",
            {
              staticClass: "platte",
              style: {
                width:
                  _vm.toStagesAnnotation.find(
                    (stage) => stage.stageName == _vm.targetStage
                  ) &&
                  _vm.toStagesAnnotation.find(
                    (stage) => stage.stageName == _vm.targetStage
                  ).errorDict
                    ? _vm.toStagesAnnotation.find(
                        (s) => s.stageName == _vm.targetStage
                      ).isShow
                      ? _vm.toStagesAnnotation.find(
                          (stage) => stage.stageName == _vm.targetStage
                        ).errorDict.length == 4
                        ? "610px"
                        : _vm.toStagesAnnotation.find(
                            (stage) => stage.stageName == _vm.targetStage
                          ).errorDict.length == 3
                        ? "550px"
                        : _vm.toStagesAnnotation.find(
                            (stage) => stage.stageName == _vm.targetStage
                          ).errorDict.length == 2
                        ? "520px"
                        : "490px"
                      : "520px"
                    : "396px",
              },
            },
            [
              _vm.toStagesAnnotation.length
                ? _c(
                    "div",
                    {
                      staticClass: "platte-select",
                      style: {
                        paddingLeft: "14px",
                        width:
                          _vm.toStagesAnnotation.length == 4
                            ? "380px"
                            : _vm.toStagesAnnotation.length == 5
                            ? "420px"
                            : _vm.toStagesAnnotation.length == 6
                            ? "460px"
                            : "",
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "16px" } }, [
                        _vm._v("\n        To:\n      "),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { "margin-left": "10px" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.toStageChange(null)
                                },
                              },
                              model: {
                                value: _vm.targetStage,
                                callback: function ($$v) {
                                  _vm.targetStage = $$v
                                },
                                expression: "targetStage",
                              },
                            },
                            _vm._l(
                              _vm.toStagesAnnotation.filter((s) => {
                                return (
                                  (_vm.changeAll && !_vm.contentEdit) ||
                                  s.stageName == _vm.targetStage
                                )
                              }),
                              function (stage) {
                                return _c(
                                  "a-radio",
                                  {
                                    key: stage.stageName,
                                    attrs: {
                                      value: stage.stageName,
                                      disabled:
                                        !_vm.changeAll ||
                                        _vm.isAutoSave ||
                                        _vm.contentEdit,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(stage.stageName) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "platte-content",
                  on: {
                    mouseup: _vm.platteMouseup,
                    mousedown: _vm.platteMouseDown,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "platte-content-top",
                      on: { mouseup: _vm.platteMouseup },
                    },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "platte-option",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.selectPlatte("rect")
                              },
                              mouseover: function ($event) {
                                _vm.color1 = _vm.color
                              },
                              mouseout: function ($event) {
                                _vm.color1 = "#4a4a4a"
                              },
                            },
                          },
                          [
                            _c("square", {
                              attrs: {
                                theme: "outline",
                                size: "21",
                                fill:
                                  _vm.platteType == "rect"
                                    ? _vm.color
                                    : _vm.color1,
                                strokeWidth: 3,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "platte-option",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.selectPlatte("ring")
                              },
                              mouseover: function ($event) {
                                _vm.color2 = _vm.color
                              },
                              mouseout: function ($event) {
                                _vm.color2 = "#4a4a4a"
                              },
                            },
                          },
                          [
                            _c("round", {
                              attrs: {
                                theme: "outline",
                                size: "21",
                                fill:
                                  _vm.platteType == "ring"
                                    ? _vm.color
                                    : _vm.color2,
                                strokeWidth: 3,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "platte-option",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.selectPlatte("line")
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-line-fill icon",
                              style: {
                                fontSize: "24px",
                                color:
                                  _vm.platteType == "line" ? _vm.color : "",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "platte-option",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.selectPlatte("arrow")
                              },
                              mouseover: function ($event) {
                                _vm.color3 = _vm.color
                              },
                              mouseout: function ($event) {
                                _vm.color3 = "#4a4a4a"
                              },
                            },
                          },
                          [
                            _c("arrow-right-up", {
                              attrs: {
                                theme: "outline",
                                size: "24",
                                fill:
                                  _vm.platteType == "arrow"
                                    ? _vm.color
                                    : _vm.color3,
                                strokeWidth: 3,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "platte-option",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.selectPlatte("pencil")
                              },
                              mouseover: function ($event) {
                                _vm.color4 = _vm.color
                              },
                              mouseout: function ($event) {
                                _vm.color4 = "#4a4a4a"
                              },
                            },
                          },
                          [
                            _c("pencil", {
                              attrs: {
                                theme: "outline",
                                size: "20",
                                fill:
                                  _vm.platteType == "pencil"
                                    ? _vm.color
                                    : _vm.color4,
                                strokeWidth: 3,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "platte-option",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.selectPlatte("text")
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                style: {
                                  fontSize: "21px",
                                  fontWeight: 500,
                                  color:
                                    _vm.platteType == "text" ? _vm.color : "",
                                },
                              },
                              [_vm._v("A")]
                            ),
                          ]
                        ),
                      ]),
                      _vm.toStagesAnnotation.find(
                        (stage) => stage.stageName == _vm.targetStage
                      ) &&
                      _vm.toStagesAnnotation.find(
                        (stage) => stage.stageName == _vm.targetStage
                      ).errorDict
                        ? _c(
                            "div",
                            { style: `display:flex;align-items: center;` },
                            [
                              _c(
                                "div",
                                {
                                  class: !_vm.platteInjuryType
                                    ? "platte-error platte-error-select"
                                    : "platte-error",
                                  staticStyle: {
                                    "border-left": "1px solid rgba(0,0,0,0.25)",
                                    "justify-content": "center",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectPlatteError(0)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      _vm._v("无"),
                                      !_vm.platteInjuryType
                                        ? _c("a-icon", {
                                            attrs: {
                                              type: "check-circle",
                                              theme: "filled",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._l(
                                _vm.toStagesAnnotation.find(
                                  (stage) => stage.stageName == _vm.targetStage
                                ).errorDict,
                                function (error) {
                                  return _c(
                                    "div",
                                    {
                                      key: error.id,
                                      class:
                                        _vm.platteInjuryType == error.type
                                          ? "platte-error platte-error-select"
                                          : "platte-error",
                                      style: {
                                        color:
                                          error.dictName.indexOf("硬") > -1 ||
                                          error.dictName.indexOf("错翻") > -1
                                            ? "red"
                                            : "orange",
                                        justifyContent: "center",
                                        width:
                                          error.dictName.length > 1
                                            ? "50px"
                                            : "",
                                        paddingLeft:
                                          error.dictName.length > 1
                                            ? "5px"
                                            : "",
                                        paddingRight:
                                          error.dictName.length > 1
                                            ? "5px"
                                            : "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectPlatteError(
                                            error.type,
                                            error.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          style: {
                                            width:
                                              error.dictName.length > 1
                                                ? "38px"
                                                : "",
                                          },
                                        },
                                        [
                                          _vm._v(_vm._s(error.dictName)),
                                          _vm.platteInjuryType == error.type
                                            ? _c("a-icon", {
                                                attrs: {
                                                  type: "check-circle",
                                                  theme: "filled",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            flex: "1",
                            "padding-top": "12px",
                            "padding-bottom": "11px",
                          },
                          on: {
                            mousemove: function ($event) {
                              _vm.noDrag = true
                            },
                            mouseout: function ($event) {
                              _vm.noDrag = false
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "platte-action",
                              staticStyle: {
                                "border-left": "1px solid rgba(0,0,0,0.25)",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.undo.apply(null, arguments)
                                },
                                mouseover: function ($event) {
                                  _vm.color5 = _vm.color
                                },
                                mouseout: function ($event) {
                                  _vm.color5 = "#4a4a4a"
                                },
                              },
                            },
                            [
                              _c("undo", {
                                attrs: {
                                  theme: "outline",
                                  size: "20",
                                  fill: _vm.color5,
                                  strokeWidth: 3,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "platte-action",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.closePlatte.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("a-icon", {
                                staticStyle: { "font-size": "18px" },
                                attrs: { type: "close" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "platte-action",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.savePlatte.apply(null, arguments)
                                },
                              },
                            },
                            [
                              !_vm.platteLoading
                                ? _c("a-icon", {
                                    staticStyle: { "font-size": "18px" },
                                    attrs: { type: "check" },
                                  })
                                : _c(
                                    "a-spin",
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "font-size": "24px" },
                                        attrs: {
                                          slot: "indicator",
                                          type: "loading",
                                          spin: "",
                                        },
                                        slot: "indicator",
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "platte-content-bottom",
                      on: {
                        mousedown: function ($event) {
                          $event.stopPropagation()
                        },
                        mouseup: _vm.platteMouseup,
                      },
                    },
                    [
                      _c("j-editor", {
                        ref: "platteInput",
                        attrs: {
                          isWindows: _vm.isWindows,
                          disabled: _vm.platteLoading,
                          maxWidth: "50px",
                          toolbar: [],
                        },
                        on: {
                          postValue: _vm.savePlatte,
                          redo: _vm.redo,
                          undo: _vm.undo,
                        },
                        model: {
                          value: _vm.platteValue,
                          callback: function ($$v) {
                            _vm.platteValue = $$v
                          },
                          expression: "platteValue",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm.platteType && !_vm.platteLoading
                ? _c(
                    "div",
                    {
                      staticClass: "platte-top",
                      on: { mouseup: _vm.platteMouseup },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "platte-top-left" }, [
                          _c("div", [
                            _c("div", { staticClass: "platte-top-label" }, [
                              _vm._v("大小"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "platte-top-slider",
                                staticStyle: { "padding-left": "10px" },
                              },
                              [
                                _c("a-slider", {
                                  attrs: {
                                    defaultValue: 3,
                                    max: _vm.maxSliderValue,
                                    min: _vm.minSliderValue,
                                  },
                                  on: { change: _vm.changeSlider },
                                  model: {
                                    value: _vm.sliderValue,
                                    callback: function ($$v) {
                                      _vm.sliderValue = $$v
                                    },
                                    expression: "sliderValue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "platte-top-right" }, [
                          _c("div", [
                            _c("div", {
                              class:
                                _vm.platteColor == "red"
                                  ? "platte-top-color-item platte-top-color-item-selected"
                                  : "platte-top-color-item",
                              style: { backgroundColor: "red" },
                              on: {
                                click: function ($event) {
                                  return _vm.changePlatteColor("red")
                                },
                              },
                            }),
                            _c("div", {
                              class:
                                _vm.platteColor == "yellow"
                                  ? "platte-top-color-item platte-top-color-item-selected"
                                  : "platte-top-color-item",
                              style: { backgroundColor: "yellow" },
                              on: {
                                click: function ($event) {
                                  return _vm.changePlatteColor("yellow")
                                },
                              },
                            }),
                            _c("div", {
                              class:
                                _vm.platteColor == "green"
                                  ? "platte-top-color-item platte-top-color-item-selected"
                                  : "platte-top-color-item",
                              style: { backgroundColor: "green" },
                              on: {
                                click: function ($event) {
                                  return _vm.changePlatteColor("green")
                                },
                              },
                            }),
                            _c("div", {
                              class:
                                _vm.platteColor == "blue"
                                  ? "platte-top-color-item platte-top-color-item-selected"
                                  : "platte-top-color-item",
                              style: { backgroundColor: "blue" },
                              on: {
                                click: function ($event) {
                                  return _vm.changePlatteColor("blue")
                                },
                              },
                            }),
                            _c("div", {
                              class:
                                _vm.platteColor == "#fff"
                                  ? "platte-top-color-item platte-top-color-item-selected"
                                  : "platte-top-color-item",
                              style: {
                                backgroundColor: "#fff",
                                border: "1px solid #4a4a4a",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changePlatteColor("#fff")
                                },
                              },
                            }),
                            _c("div", {
                              class:
                                _vm.platteColor == "#999999"
                                  ? "platte-top-color-item platte-top-color-item-selected"
                                  : "platte-top-color-item",
                              style: { backgroundColor: "#999999" },
                              on: {
                                click: function ($event) {
                                  return _vm.changePlatteColor("#999999")
                                },
                              },
                            }),
                            _c("div", {
                              class:
                                _vm.platteColor == "#000"
                                  ? "platte-top-color-item platte-top-color-item-selected"
                                  : "platte-top-color-item",
                              style: { backgroundColor: "#000" },
                              on: {
                                click: function ($event) {
                                  return _vm.changePlatteColor("#000")
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.selectedDiff
        ? _c("div", { staticClass: "diff-model" }, [
            _c("div", { staticClass: "diff-model-title" }, [
              _vm._v("\n      图像对比\n    "),
            ]),
            _c("div", { staticClass: "diff-model-body" }, [
              _c("div", [
                _c("div", [
                  _c("div", { staticStyle: { width: "40%" } }, [
                    _vm._v("对比容差："),
                  ]),
                  _c(
                    "div",
                    [
                      _c("a-input-number", {
                        attrs: { min: 0 },
                        model: {
                          value: _vm.diffData.pixelToleranceLevel,
                          callback: function ($$v) {
                            _vm.$set(_vm.diffData, "pixelToleranceLevel", $$v)
                          },
                          expression: "diffData.pixelToleranceLevel",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticStyle: { "margin-top": "5px" } }, [
                  _c("div", { staticStyle: { width: "40%" } }, [
                    _vm._v("参照稿："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "diff-scroll" },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.diffData.leftTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.diffData, "leftTitle", $$v)
                            },
                            expression: "diffData.leftTitle",
                          },
                        },
                        _vm._l(
                          _vm.allOption.filter(
                            (option) =>
                              option.title != "原稿" &&
                              option.title != "原稿JPG" &&
                              option.title != "实时" &&
                              (option.title.indexOf(_vm.diffFilterString) >
                                -1 ||
                                (_vm.diffFilterString == "上色" &&
                                  option.title.indexOf("后期") > -1) ||
                                (_vm.diffFilterString == "后期" &&
                                  option.title.indexOf("上色") > -1))
                          ),
                          function (option) {
                            return _c(
                              "a-radio",
                              {
                                key: option.id,
                                style: _vm.radioStyle,
                                attrs: { value: option.title },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(option.title) +
                                    "\n              "
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", [
                  _c("div", { staticStyle: { width: "40%" } }, [
                    _vm._v("对齐方式："),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.diffData.flag,
                            callback: function ($$v) {
                              _vm.$set(_vm.diffData, "flag", $$v)
                            },
                            expression: "diffData.flag",
                          },
                        },
                        [
                          _c(
                            "a-radio",
                            { style: _vm.radioStyle, attrs: { value: 1 } },
                            [
                              _vm._v(
                                "\n                顶部对齐\n              "
                              ),
                            ]
                          ),
                          _c(
                            "a-radio",
                            { style: _vm.radioStyle, attrs: { value: 2 } },
                            [
                              _vm._v(
                                "\n                底部对齐\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", [
                  _c("div", { staticStyle: { width: "40%" } }, [
                    _vm._v("标记大小："),
                  ]),
                  _c(
                    "div",
                    [
                      _c("a-input-number", {
                        attrs: { min: 0 },
                        model: {
                          value: _vm.diffData.threshold,
                          callback: function ($$v) {
                            _vm.$set(_vm.diffData, "threshold", $$v)
                          },
                          expression: "diffData.threshold",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticStyle: { "margin-top": "5px" } }, [
                  _c("div", { staticStyle: { width: "40%" } }, [
                    _vm._v("对比稿："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "diff-scroll" },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.diffData.rightTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.diffData, "rightTitle", $$v)
                            },
                            expression: "diffData.rightTitle",
                          },
                        },
                        _vm._l(
                          _vm.allOption.filter(
                            (option) =>
                              option.title != "原稿" &&
                              option.title != "原稿JPG" &&
                              option.title != "实时" &&
                              (option.title.indexOf(_vm.diffFilterString) >
                                -1 ||
                                (_vm.diffFilterString == "上色" &&
                                  option.title.indexOf("后期") > -1) ||
                                (_vm.diffFilterString == "后期" &&
                                  option.title.indexOf("上色") > -1))
                          ),
                          function (option) {
                            return _c(
                              "a-radio",
                              {
                                key: option.id,
                                style: _vm.radioStyle,
                                attrs: { value: option.title },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(option.title) +
                                    "\n              "
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "diff-model-footer" },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-right": "12px" },
                    attrs: { size: "small" },
                    on: { click: _vm.closeDiffModal },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "a-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.diff },
                  },
                  [_vm._v("对比")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("errorModal", {
        ref: "errorModal",
        attrs: { dictList: _vm.dictList },
        on: {
          clearSelectErrorType: _vm.clearSelectErrorType,
          changeIsSelectedLocal: _vm.changeIsSelectedLocal,
          errorChangePlatte: _vm.errorChangePlatte,
          errorChange: _vm.errorChange,
        },
      }),
      _c("div", { attrs: { contenteditable: true, id: "platteEditDiv" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }